import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from './tooltip'
import { HelpCircle, Info } from 'lucide-react'

export default function PricingTooltip({ tooltip }: { tooltip: string }) {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>
          <Info className="size-4 text-muted-foreground opacity-70" />
        </TooltipTrigger>
        <TooltipContent className="max-w-[300px] text-center mx-6">
          <p>{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
